import { defineStore } from 'pinia'
import type { CredentialResponse } from 'google-one-tap'
import type { SocialToken } from 'auth/types/token'
import { EContent } from 'auth/enums/dialog-contents'

export const useAuthSocialLoginStore = defineStore(
  'authSocialLoginStore',
  () => {
    const authStore = useAuthStore()
    const { $notify } = useNuxtApp()
    const runtimeConfig = useRuntimeConfig()

    const loginWithFacebook = async (authResponse: fb.AuthResponse) => {
      authStore.isProcessing = true
      const facebookUrl =
        authStore.dialogContent === EContent.SIGN_UP_FIRST_CREATOR
          ? 'facebook/creator'
          : authStore.dialogContent === EContent.SIGN_UP_FIRST_BRAND
          ? 'facebook/buyer'
          : 'facebook'

      try {
        const { data, error } = await useApi<SocialToken>(facebookUrl, {
          baseURL: runtimeConfig.public.authUrl,
          method: 'post',
          body: authResponse,
        })

        if (error.value) {
          throw new Error(error.value?.message)
        }

        await authStore.setAuthAndCustomerData({
          newAccessToken: data.value?.token,
          maxAge: data.value?.valid,
          newRefreshToken: 'facebook',
          isExternalAuthType: true,
        })
        useKlaviyo('identify')
      } catch (error) {
        if (
          (error as Error)?.message ===
          "User doesn't exist yet, please sign up to proceed."
        ) {
          redirectToRegistrationForm()
        }

        $notify({
          text: (error as Error)?.message || 'Failed to login via Facebook',
          severity: 'error',
        })
      } finally {
        authStore.isProcessing = false
      }
    }

    const loginWithGoogle = async (authResponse: CredentialResponse) => {
      authStore.isProcessing = true
      const googleUrl =
        authStore.dialogContent === EContent.SIGN_UP_FIRST_CREATOR
          ? 'google/creator'
          : authStore.dialogContent === EContent.SIGN_UP_FIRST_BRAND
          ? 'google/buyer'
          : 'google'

      try {
        const { data, error } = await useApi<SocialToken>(googleUrl, {
          baseURL: runtimeConfig.public.authUrl,
          method: 'post',
          body: authResponse,
        })

        if (error.value) {
          throw new Error(error.value?.message)
        }

        await authStore.setAuthAndCustomerData({
          newAccessToken: data.value?.token,
          maxAge: data.value?.valid,
          newRefreshToken: 'google',
          isExternalAuthType: true,
        })
        useKlaviyo('identify')
      } catch (error) {
        if (
          (error as Error)?.message ===
          "User doesn't exist yet, please sign up to proceed."
        ) {
          redirectToRegistrationForm()
        }

        $notify({
          text: (error as Error)?.message || 'Failed to login via Google',
          severity: 'error',
        })
      } finally {
        authStore.isProcessing = false
      }
    }

    const redirectToRegistrationForm = () => {
      authStore.switchContent(
        useRoute().name === 'become-a-creator-page'
          ? EContent.SIGN_UP_FIRST_CREATOR
          : EContent.SIGN_UP_FIRST_BRAND,
      )
    }

    return {
      loginWithFacebook,
      loginWithGoogle,
    }
  },
)
